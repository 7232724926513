import {
    Stack,
    Box,
    Text,
    Divider,
    useColorModeValue
} from '@chakra-ui/react'

import theme from '../../Config/theme'

import { FaQuestion } from "react-icons/fa";

const FaqItem = ({ question, answer }) => {
    const bgColor = useColorModeValue('gray.100', 'gray.800')
    const borderColor = useColorModeValue(theme.borderColor.borderColorLight, theme.borderColor.borderColorDark)
    
    return (
        <Stack
            direction={'column'}
            bgColor={bgColor}
            border={'1px solid'}
            borderColor={borderColor}
            spacing={5}
            p={5}
            borderRadius={'lg'}
            textAlign={'left'}
            style={{ transition: 'transform .2s' }}
            _hover={{ transform: 'scale(1.03)', boxShadow: `5px 5px ${theme.default.defaultColor}` }}
        >
            <Stack direction={'row'} align={'center'} spacing={2}>
                <Box
                    bgColor={theme.default.defaultColor}
                    borderRadius={'full'}
                    p={2}
                >
                    <FaQuestion size={24} color={'white'} />
                </Box>
                <Text fontSize={'xl'}>{question}</Text>
            </Stack>
            <Divider borderColor={borderColor} />
            <Text fontSize={'lg'}>{answer}</Text>
        </Stack>
    )
}

export default FaqItem