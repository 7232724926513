import { Link } from '@remix-run/react'

import {
    Container,
    Stack,
    Heading,
    Button,
    Flex,
    SimpleGrid,
    useColorModeValue,
} from '@chakra-ui/react'

import theme from '../../Config/theme'

import CustomHighlight from '../Elements/CustomHighlight';

import FaqItem from './FaqItem'

import { FaArrowRight } from "react-icons/fa";

const Faq = ({ data }) => {
    return (
        <Flex
            w={'full'}
            py={10}
        >
            <Container
                maxW={'6xl'}
            >
                <Stack alignItems={'center'} spacing={'5%'} textAlign={'center'}>
                    <Heading fontWeight={700} fontSize={'4xl'}>
                        <CustomHighlight query={['Frequently Asked Questions']}>Frequently Asked Questions</CustomHighlight>
                    </Heading>
                    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} w={'full'}>
                        {
                            data?.map(faq => {
                                return (
                                    <FaqItem question={faq.question} answer={faq.answer} key={faq.question} />
                                )
                            })
                        }
                    </SimpleGrid>

                    <Link to='/faq'>
                        <Button
                            _active={{}}
                            _focus={{}}
                            _hover={{}}
                            size={'lg'}
                            borderRadius={'full'}
                            //fontWeight={700}
                            rightIcon={<FaArrowRight />}
                            color={'white'}
                            bgColor={theme.default.defaultColor}
                        >
                            View All FAQs
                        </Button>
                    </Link>
                </Stack>
            </Container>
        </Flex>
    )
}

export default Faq